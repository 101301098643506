.Quiz {
    width: 100%;
    height: 100%;
    background-color: #fff;

    .home-nav {
        display: flex;
        justify-content: flex-start;
    }

    .content-header {
        background-color: #e5f8ff;

    }

    .unit-list-row {
        width: 100%;
        display: flex;

        .course-list-comp {
            width: 33.333%;

            >.cardComp {
                margin: 0 auto;
            }
        }

        .unit-list-comp {
            width: 25%;

            >.quiz-unit-item {
                margin: 0 auto;
                margin-bottom: 10px;
            }
        }
    }

    .content-header {
        color: black;
        display: flex;
        padding: 0px 50px;

        .header-ret {
            font-size: 18px;
        }

        .header-title {
            color: black;
            margin: 0 auto;
        }
    }

}

@media screen and (max-width: 720px) {
    .Quiz {
        .unit-list-row {
            flex-direction: column;
            margin: auto;


            .course-list-comp {
                width: 100%;
                margin-bottom: 10px;

            }

            .unit-list-comp {
                width: 100%;
                margin-bottom: 20px;


                >.UnitComp {
                    margin: 0 auto;
                }
            }
        }
    }
}
.cardComp {
    height: 222px;
    // height: 11.6vw;
    width: 284px;
    // width: 14.8vw;
    font-weight: 500;
    border: 1px #ffcd7c solid;
    border-radius: 5px;
    // margin: 0 auto;
    // margin-top: 78px;

    .orange-div {
        height: 222px;
        text-align: center;
        font-size: 1.2rem;

        .units-title {
            height: 66px;
            line-height: 66px;
            font-size: 1.6rem;
        }



        .units-content {
            height: 154px;
            background-color: #ffcd7c;
            border-radius: 0 0 5px 5px;
            font-size: 1.4rem;


            .orange-text1-p,
            .orange-text2-p {
                height: 48px;
                line-height: 76px;
                margin-bottom: 0;
                text-align: left;
                margin-left: 42px;
            }

            .orange-text3-p {
                margin-bottom: 0;
                text-align: left;
                margin-left: 42px;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 720px) {


    .cardComp {
        height: 150px;
        margin: 28px auto;


        .orange-div {
            height: 150px;

            .units-title {
                height: 40px;
                line-height: 40px;
                font-size: 1.4rem;
            }

            .units-content {
                height: 110px;
                background-color: #ffcd7c;
                border-radius: 0 0 5px 5px;
                font-size: 1.4rem;

                .orange-text1-p,
                .orange-text2-p {
                    height: 34px;
                    line-height: 34px;
                }
            }
        }
    }
}
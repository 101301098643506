.ExamQuestion {
    .examQuestion-hander {
        display: flex;
        align-items: center;

        .hander-text {

            margin: 0 auto;
        }
    }

    .content {
        min-height: 50px;

        .audio-icon {
            width: 40px;
            margin: 30px auto 40px;
        }

        .word {
            font-size: 2rem;
            font-weight: 600;
        }

        .pinyin {
            margin: 16px 0 55px;
        }

        .type-img {
            width: 200px;
            max-height: 200px;
            margin: 20px auto;
        }
    }




    .carousel-div {
        // width: 100%;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        font-size: 1.4rem;
        color: #70828c;
        margin-top: 24px;

        .carousel-content {
            background-color: #FFF;
            width: 30%;
            margin: 0 auto 20px;
            padding-top: 10px;
        }

        .btn-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin-bottom: 24px;


            .btn {
                min-width: 150px;
                width: auto;
                height: 44px;
                box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1), 0 1px 10px -10px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                margin-bottom: 20px;
                color: #fff;
                font-size: 1.2rem;
                font-weight: 700px;
                text-shadow: 1px 2px 1px rgba(1, 1, 1, 1);
            }

            .not-btn {
                color: #aeaeae;
            }

            .yes-btn {
                background-color: #77d752;
            }

            .no-btn {
                background-color: #81cce8;
            }

            .btn:hover {
                background-color: #77d752;
            }
        }

        .footer-btn {
            display: flex;
            width: 50.5%;
            margin: 0 auto;
            justify-content: space-around;


            .inc-btn,
            .next-btn {
                width: 30.3%;
                height: 52px;
                border-radius: 40px;
                color: #fff;
            }

            .inc-btn {
                background-color: #c1c1c1;
            }

            .next-btn {
                background-color: #01a9e8;
            }
        }
    }
}

@media only screen and (max-width:720px) {
    .ExamQuestion {
        .carousel-div {
            // width: 100%;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            font-size: 1.4rem;
            color: #70828c;
            margin-top: 24px;

            .carousel-content {
                background-color: #FFF;
                width: 90%;
                margin: 0 auto 20px;
                padding-top: 10px;
            }

            .btn-div {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 24px;

                .btn {
                    width: 156px;
                    height: 48px;
                    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1), 0 1px 10px -10px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    margin-bottom: 20px;
                    color: #fff;
                }

                .not-btn {
                    color: #aeaeae;
                }

                .yes-btn {
                    background-color: #77d752;
                }

                .no-btn {
                    background-color: #81cce8;
                }
            }

            .footer-btn {
                width: 100%;
                display: flex;
                justify-content: space-around;

                .inc-btn,
                .next-btn {
                    width: 30.3%;
                    height: 40px;
                    border-radius: 30px;
                    color: #fff;
                    font-size: 12px;
                }

                .inc-btn {
                    background-color: #c1c1c1;
                }

                .next-btn {
                    background-color: #01a9e8;
                }
            }
        }


    }
}
.lessonComp {
    // width: 62%;
    // margin: auto;
    height: 86px;
    border: 1.5px #bfcfcf solid;
    border-radius: 10px;
    padding: 8px 50px 8px 30px;
    /* clear: both; */
    margin-bottom: 12px;
    display: flex;
    justify-content: space;
    position: relative;
    background-color: #e0dedf;

    .class-video-div {
        width: 126px;
        height: 100%;

        img {
            display: inline-block;
            width: 126px;
            height: 100%;
        }
    }

    /* 课程介绍 */
    .class-info-div {
        margin-left: 1.8rem;
        width: auto;
        max-width: 80%;
        padding-top: 1.8rem;
        display: grid;
        align-content: center;
        grid-template-rows: 16px auto;
        overflow: hidden;
    }

    .class-isshow-div {
        line-height: 100%;
        padding-top: 2rem;
        width: auto;

        .lock {
            display: inline-block;
            width: 40px;
        }
    }
}

.lesson-title {
    font-weight: 700;
}

.class-isshow-div {
    position: absolute;
    right: 8%;
}

/* 移动端 */
@media screen and (max-width: 720px) {

    .lessonComp {
        width: 100%;
        padding: 8px 10px;

        .class-info-div {
            width: 13rem;

        }

        .class-isshow-div {
            width: auto;
        }

        .lesson-introduce {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }

    .ant-layout .ant-layout-content {
        padding: 0 !important;
    }

}
.quiz-unit-item {
    height: 254px;
    width: 194px;
    background-color: #ececec;
    border-radius: 10px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1), 0 1px 10px -10px rgba(0, 0, 0, 0.1);
    border: 2px rgba(0, 0, 0, 0.02) solid;
    text-align: center;
    margin: auto;

    .head-portrait {
        width: 102px;
        height: 102px;
        border-radius: 50%;
        border: 3px #00a7e6 solid;
        margin: 38px auto 16px;
        padding: 10px;
    }

    #head-portrait-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 3px #bfbfbf solid;
    }

    .title-text {
        font-size: 1.8rem;
        font-weight: 700;
        height: 18px;
        line-height: 18px;
    }

    .total-number {
        font-size: 1.4rem;
        margin-top: 15px;
    }

    .total-number>span {
        color: #ffcd7c;
    }
}

// 移动端
@media screen and (max-width: 720px) {

    // 
    .quiz-unit-item {
        height: 180px;
        width: 295px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        // margin: 20px 17px 15px 17px;

        .app-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .head-portrait {
            width: 102px;
            height: 102px;
            border-radius: 50%;
            border: 3px #00a7e6 solid;
            margin: 0;
            padding: 10px;
        }
    }
}
.video-player {
    width: 100%;
   height: 400px;
    background-color: #fff;

    .video-js {
        width: 100%;
        height: 95%;
    }

    .btns {
        float: right;

        button {
            margin: 5px 10px;
            background-color: #ebd24e;
        }
    }
}
.fail-div {
    width: 100%;
    height: 100%;
    background-color: #fff;

    .fail-item {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.home-nav {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: #e4f7fe;
    font-size: 12px;
    line-height: 48px;
    padding: 0px 50px;

    .Purchasecourse {
        // background-color: #01a9e8;
        font-size: 30px;
        color: #01a9e8;
        margin-right: 20px;
        border-radius: 20px;
        padding: 0 4px;
        height: 40px;
        line-height: 40px;
    }

    .nav-quiz {
        display: flex;

        img {
            width: 22px;
            margin-bottom: 4px;
        }
    }

    .nav-flashcards {
        display: flex;
        margin-left: 30px;

        img {
            width: 28px;
        }
    }
}

.course-list {
    width: 100%;
    height: 100%;
    padding: 30px 30px;
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow: -moz-hidden-unscrollable;
    background-color: #fff;

    .lesson-list {
        background-color: #e4f7fe;
    }

    // ::-webkit-scrollbar {
    //     width: 0;
    // }
}


.content-header {
    color: #fff;
    display: flex;
    padding: 0px 50px !important;

    .header-ret {
        font-size: 18px;
    }

    .header-title {
        color: #fff;
        margin: 0 auto;
    }
}

@media only screen and (max-width:720px) {
    .content-header {
        color: #fff;
        display: flex;
        padding: 0px 50px !important;

        .header-ret {
            font-size: 12px;
            font-weight: 600;

            >img {
                width: 20px;
            }
        }

        .header-title {
            color: #fff;
            margin: 0 auto;

            >img {
                width: 24px;
            }
        }
    }
}
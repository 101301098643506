.ant-layout-header {
    padding: 0;
}

.home {
    background-color: #e4f7fe !important;
    height: 100%;

    .content-header {
        background-color: #01a9e8;
        height: 80px;
        line-height: 80px;
    }

    .home-header {
        .ant-layout.ant-layout-has-sider {
            flex-direction: column;
        }
    }

    // .content-header{
    //     display: flex;
    //     color: #fff;
    // }

    .unit-div {
        display: flex;
        margin-bottom: 10px;

        >div {
            width: 33.333%;

            >.UnitComp {
                margin: 0 auto;
            }
        }
    }

    .home-nav {
        display: flex;
        align-content: center;
        height: 100%;
        background-color: #e4f7fe;
        font-size: 12px;
        line-height: 48px;
        padding: 0px 50px;

        .nav-quiz {
            display: flex;

            img {
                width: 22px;
                margin-bottom: 4px;
            }
        }

        .nav-flashcards {
            display: flex;
            margin-left: 30px;

            img {
                width: 28px;
            }
        }
    }

    .Quiz,
    .Flashcards {
        padding: 0px 24px 24px;
    }

    .unit-list,
    .flashcards-list {
        width: 100%;
        height: 100%;
        padding: 30px 30px;
        overflow-y: auto;
        -ms-overflow-style: none;
        overflow: -moz-hidden-unscrollable;
        background-color: #e4f7fe;

        ::-webkit-scrollbar {
            width: 0;
        }

        .unit-list-row {
            width: 100%;
            display: flex;

        }

        .course-list-comp {
            width: 25%;
        }

        .unit-list-comp {
            width: 25%;
            margin-bottom: 90px;

            >.UnitComp {
                margin: 0 auto;
            }
        }
    }


    .quiz-title,
    .flashcards-title {
        height: 64px;
        line-height: 64px;
        font-size: 20px;
        font-weight: 700;
    }


    .lessonComp {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 10px;
    }

}


// 移动端
@media screen and (max-width: 720px) {
    .home {
        .ant-layout-sider {
            width: 100% !important;
            flex: 0 0 100% !important;
            max-width: 100%;
            min-width: 100% !important;
        }

        .content-header {
            background-color: #01a9e8;
            height: 60px;
            line-height: 60px;
        }

        .unit-list,
        .flashcards-list {
            width: 100%;
            padding: 10px 0px;
            background-color: #e4f7fe;

            .unit-list-row {
                flex-direction: column;
                margin: auto;

                .course-list-comp {
                    width: 100%;
                }

                .unit-list-comp {
                    width: 100%;
                    margin-bottom: 20px;


                    >.UnitComp {
                        margin: 0 auto;
                    }
                }
            }
        }

        .courselves-review {
            margin-bottom: 14px;
        }

        .quiz-title,
        .flashcards-title {
            height: 64px;
            line-height: 64px;
            font-size: 20px;
            font-weight: 700;
        }

        .courselves-review {
            margin-bottom: 14px;
        }

        .lessonComp {
            width: 90%;
            padding: 8px 10px;

            .class-info-div {
                width: 13rem;

            }

            .class-isshow-div {
                width: auto;
            }

        }
    }
}
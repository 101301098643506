.UnitComp {
    width: 190px;

    // margin: 0 auto;


    .util-img-div {
        position: relative;
        height: 190px;
        width: 100%;
        border: 4px #00a7e6 solid;
        border-radius: 50%;
        padding: 10px;
        background-color: #fff;

        .img {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            // border: 4px #bfbfbf solid;
            border-radius: 50%;
        }

        .lock {
            position: absolute;
            top: 60%;
            right: -10%;
            width: 60px;
        }
    }

    .util-text {
        height: 50px;
        margin-top: 30px;
        text-align: center;

        .unit-title-text {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .unit-title-text2 {
            font-weight: 500;
        }
    }
}

/* 移动端 */
@media screen and (max-width: 720px) {

    .UnitComp {
        width: 120px;
        height: auto;
        margin: 0 auto;
        padding: 10px;

        .util-img-div {
            position: relative;
            height: 90px;
            width: 90px;
            border: 4px #00a7e6 solid;
            border-radius: 50%;
            padding: 10px;
            background-color: #fff;
            margin: auto;

            .img {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                border: 3px #bfbfbf solid;
                border-radius: 50%;
            }

            .lock {
                position: absolute;
                top: 60%;
                right: -10%;
                width: 50px;
            }
        }

        .util-text {
            height: 50px;
            margin-top: 30px;
            text-align: center;

            .unit-title-text {
                font-size: 1.4rem;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .unit-title-text2 {
                font-weight: 500;
            }
        }
    }
}
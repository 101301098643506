.myuser {
    position: absolute;
    top: 0;
    right: 0;
    width: 390px;

    .ant-layout-sider-children {
        background-color: #e4f7fe;
    }

    .sider-w {
        transition: opacity 2s;
        -moz-transition: opacity 2s;
        /* Firefox 4 */
        -webkit-transition: opacity 2s;
        /* Safari and Chrome */
        -o-transition: opacity 2s;
        /* Opera */
        background-color: #fff !important;

        // 我的信息
        .users-div {
            height: 410px;
            margin: 0px auto 14px;
            padding: 56px 38px;
            background-color: #fff;
            position: relative;

            .off-users {
                position: absolute;
                top: 10px;
            }

            /* 个人信息 */
            .head {
                height: 118px;
                margin-bottom: 74px;
                display: flex;
                justify-content: space-between;

                /* 头像 */
                .head-portrait-div {
                    height: 100%;
                    width: 124px;
                    border-radius: 10px;
                    border: 1px bisque solid;
                    float: left;
                    text-align: center;
                    // margin-right: 42px;

                    .head-portrait-icon {
                        height: 100%;
                        width: 124px;
                    }
                }

                .userData-div {

                    height: 100px;
                    text-align: center;

                    .username {
                        font-size: 2rem;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }

                    .setting-icon {
                        width: 18px;
                    }

                    .level-div {
                        width: 150px;
                        height: 28px;
                        background-color: #00a7e6;
                        border-radius: 8px;
                        text-align: center;
                        line-height: 28px;
                        color: #fff;
                        margin-top: 18px;
                    }
                }
            }

            .days-div {
                width: 100%;
                margin-right: 32px;
                height: 72px;
                position: relative;

                .daya-icon {
                    width: 42px;
                }

                /* 打卡时间 */
                .check-icon {
                    position: absolute;
                    right: 0;
                    width: 42px;
                }

            }

            .complete-div {
                position: relative;
                height: 44px;
                line-height: 44px;

                .text2-p {
                    position: absolute;
                    right: 0;

                }
            }


        }

        /* 每日目标 */
        .target-div {
            margin-top: 14px;
            height: 238px;
            background-color: #fff;
            padding: 18px 14px 30px;

            .target-text-h2 {
                font-size: 2rem;
                margin-bottom: 30px;
            }

            .progress-div {
                margin: 0 26px;

                .Lesson-div {
                    width: 100%;
                    height: 75px;
                }

                .medal-div {
                    height: 75px;
                    width: 100%;
                }
            }

            .span-right {
                display: inline-block;
                float: right;
            }



        }


        .set-text-span {
            font-size: 1.2rem;
        }

        .days-text {
            vertical-align: bottom;
        }



        .color-org {
            color: orange;
            vertical-align: bottom;
        }

        /* 统计 */
        .stats-div {
            height: 360px;
            margin-top: 14px;
            background-color: #fff;
            padding: 18px 14px 30px;

            .stats-text-h2 {
                font-size: 2rem;
                margin-bottom: 32px;
            }

            .days-goals-div {
                margin: 0 26px;

                .days1 {
                    height: 90px;

                    .span1,
                    .span2,
                    .span3 {
                        font-size: 2rem;
                        vertical-align: bottom;
                        font-weight: 500;
                    }

                    .span1 {
                        color: #f0a31f;
                    }

                    .span2 {
                        color: #d0600e;
                    }

                    .span3 {
                        color: #e23200;
                    }

                    #best-streak-icon,
                    #lessons-icon,
                    #gold-icon {
                        height: 38px;
                    }

                }
            }
        }
    }
}
.login {
    font-family: "Gilroy-Regular";
    width: 100%;
    padding: 50px 0;


    .login-text {
        height: 100%;
        width: 50%;
        max-width: 644px;
        margin: 0 auto;
        padding: 0px 6px 0px;
        color: #202046;
        border-left: 4px solid #ebd24e;
        font-size: 1.7rem;

    }

    .sign-info {
        font-size: 12px;

        span {
            color: red;
        }
    }

    .sign-in {
        width: 100px;
        height: 48px;
        background-color: yellow;
        text-align: center;
        line-height: 48px;
    }

    .login-content {
        width: 50%;
        max-width: 644px;
        margin: 0 auto;
        padding: 48px 16px 16px;
        margin-top: 30px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }

    .login-button {
        background: #ebd24e;
        // margin: 10px 0 15px;
        width: 117px;
        height: 40px;
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
        line-height: 40px;
        margin: auto;
        margin-bottom: 15px;

        margin-top: 30px;
    }

    .login-tips {
        font-size: 1.2rem;
        margin-top: 15px;
        text-align: center;
        margin-bottom: 15px;
    }

    .login-account {
        font-size: 1.4rem;
        margin-bottom: 1em;

        >span {
            color: #ebd24e;
        }
    }

    .login-footer {
        width: 50%;
        max-width: 644px;
        margin: auto;
        background: #f5f5f5;
        height: 92px;
        padding: 16px;
        box-shadow: 0 0 10px rgba(17, 8, 20, 0.2);
        border-radius: 10px;
        text-align: center;

        >div {
            font-size: 2.0rem;
        }

        .login-button-tips {
            font-size: 1.2rem;

            >span {
                color: #ebd24e;
            }
        }

    }


}

@media screen and (max-width: 720px) {
    .login {
        font-family: "Gilroy-Regular";
        width: 100%;
        padding: 50px 0;

        .login-text {
            width: 90%;
            max-width: 644px;
            margin: 0 auto;
            padding: 0px 6px 0px;
            color: #202046;
            border-left: 4px solid #ebd24e;
            font-size: 1.7rem;

        }

        .sign-info {
            font-size: 12px;

            span {
                color: red;
            }
        }

        .sign-in {
            width: 100px;
            height: 48px;
            background-color: yellow;
            text-align: center;
            line-height: 48px;
        }

        .login-content {
            width: 90%;
            max-width: 644px;
            margin: 0 auto;
            padding: 48px 16px 16px;
            margin-top: 30px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
        }

        .login-button {
            background: #ebd24e;
            // margin: 10px 0 15px;
            width: 117px;
            height: 40px;
            color: #fff;
            font-size: 1.2rem;
            text-align: center;
            line-height: 40px;
            margin-bottom: 15px;
            margin-top: 30px;
        }

        .login-tips {
            font-size: 1.2rem;
            margin-top: 15px;
            text-align: center;
            margin-bottom: 15px;
        }

        .login-account {
            font-size: 1.4rem;
            margin-bottom: 1em;

            >span {
                color: #ebd24e;
            }
        }

        .login-footer {
            width: 90%;
            max-width: 644px;
            margin: auto;
            background: #f5f5f5;
            height: 92px;
            padding: 16px;
            box-shadow: 0 0 10px rgba(17, 8, 20, 0.2);
            border-radius: 10px;
            text-align: center;

            >div {
                font-size: 2.0rem;
            }

            .login-button-tips {
                font-size: 1.2rem;

                >span {
                    color: #ebd24e;
                }
            }

        }


    }
}
.home {
    background-color: #fff !important;
    width: 100%;
    height: 100%;

    .to-learn {
        background: #f2f3ee;
        height: 48px;
        width: 100%;
        text-align: right;
        padding-right: 20px;
        font-size: 1.2rem;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >p {
            height: 30px;
            line-height: 30px;
            background-color: #ebd24e;
            color: #fff;
            font-family: bold, sans-serif;
            font-weight: 700;
            padding: 0 20px;
            border-radius: 20px;
        }

        img {
            margin-left: 20px;
            height: 18px;
            width: 64px;
        }
    }

    .class-img-pc {
        width: 100%;
        margin: auto;

        img {
            display: block;
            width: 100%;
        }
    }

    .class-img {
        display: none;
    }

    .home-div {
        padding-top: 30px;
        width: 65.42%;
        height: auto;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;

        .nav-left {
            width: 30.93%;

            .left-content {
                background-color: #ecd352;
                width: 100%;
                padding: 16px 20px;
                border-radius: 5px;

                .nav-ul {
                    // height: 40px;

                    .list-item {
                        height: 90px;
                        border: #fff 3px solid;
                        border-radius: 8px;
                        margin: 30px 0;
                        color: #fff;
                        text-align: center;
                        line-height: 90px;
                        font-size: 1.6rem;
                    }

                    // .list-item:first-child {
                    //     background: #fff;
                    //     color: #ecd352;
                    // }

                    .bag-item {
                        background: #fff;
                        color: #252525;
                    }
                }
            }
        }

        .middle-div {
            width: 11.78%;
            padding: 48px 20px;
            height: 90px;
            line-height: 90px;
            text-align: center;

            .left-img {
                display: inline-block;
                position: relative;
                top: 0px;
            }

            .bottom-img {
                display: none;
            }
        }

        .home-right {
            width: 57%;
            border-radius: 5px;
            padding-top: 14px;

            .home-right-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 0 auto;

                .ceshi {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    .flashcards-btn,
                    .quiz-btn {
                        margin-top: 30px;
                        width: 41.64%;
                        height: 90px;
                        background-color: #f3f3f1;
                        font-size: 2rem;
                        font-weight: 500;
                        // box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1), 0 1px 10px -10px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                    }

                    .flashcards-btn:hover {
                        background-color: #ecd352;
                    }

                    .quiz-btn:hover {
                        background-color: #ecd352;
                    }


                }



                .video {
                    width: 100%;
                    height: 390px;
                    border: 2px #252525 solid;
                    border-radius: 5px;
                    margin-top: 0;
                    box-shadow: 0 0 0 15px #252525;

                    .my-player {
                        width: 100%;
                        height: 100%;
                    }
                }

            }

            .buy-img {
                width: 36.51%;
                margin: 0px auto;
            }
        }



    }

    @media screen and (max-width: 720px) {
        .class-img {
            display: block;
        }

        .class-img-pc {
            display: none;
        }

        .home-div {
            width: 100%;
            padding-top: 0px;
            margin-right: 0px;
            clear: both;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .nav-left {
                width: 100%;
                height: 70px;
                margin-top: 0px;

                .left-content {
                    height: 70px;
                    margin-right: 0%;
                    background-color: #ecd352;
                    padding: 15px 2px;
                    border-radius: 0px;


                    .nav-ul {
                        display: flex;
                        justify-content: space-between;

                        .list-item {
                            width: 19%;
                            height: 40px;
                            border: #fff 2px solid;
                            border-radius: 4px;
                            margin: 0px;
                            color: #fff;
                            text-align: center;
                            font-size: 1.2rem;
                            border: 1px #fff solid;
                            padding: 0px 1px;
                            font-weight: 500;
                            line-height: 20px;
                            line-clamp: 2;
                        }

                        .bag-item {
                            background: #fff;
                            color: #252525;
                        }
                    }
                }
            }

            .middle-div {
                width: 20%;
                padding: 0px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                position: relative;
                top: 0px;
                left: 0%; //+70 
                margin-top: 0px;

                .left-img {
                    display: none;
                }

                .bottom-img {
                    width: 20px;
                    display: inline-block;

                }
            }

            .home-right {
                width: 98%;
                margin: 0px auto 0;
                border: 0px #ecd352 solid;
                border-radius: 5px;
                padding-top: 14px;

                .home-right-content {
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;

                    .ceshi {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
                        margin-top: 0;
                        margin-bottom: 14px;

                        .flashcards-btn,
                        .quiz-btn {
                            font-size: 1.2rem;
                            margin-top: 24px;
                            background-color: #f3f3f1;
                            width: 35%;
                            height: 38px;
                        }
                    }

                    .video {
                        width: 80%;
                        border: 2px #252525 solid;
                        border-radius: 5px;
                        height: 200px;
                        margin-top: 0;
                        box-shadow: 0 0 0 10px #252525;
                    }



                }


            }

        }
    }
}
.input-style {
    width: 100%;
    border: 1px #dbdbdb solid;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 40px;
    border-radius: 4px;

    img {
        height: 20px;
        margin: 0 10px;
    }

    >input {
        border: 0;
        height: 100%;
        width: 100%;
        color: #0c0b0b;
        font-size: 1.6rem;
        font-size: 14px;

    }

}
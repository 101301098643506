.lesson-info-div {
    height: 182px;
    width: 65%;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    /* grid-template-columns: 190px calc(100% - 190px); */
    padding-bottom: 26px;
    margin-top: 26px;

    .img-div {
        width: 156px;
        height: 156px;
        border-radius: 50%;
        // border: 4px #00a7e6 solid;
        text-align: center;
        line-height: 146px;
        // background-color: #fff;

        img {
            width: 76.91%;
            height: 76.91%;
            border-style: none;
            // border: 4px #c5c0bc solid;
            border-radius: 50%;
        }
    }

    .text-div {
        width: auto;
        padding-left: 26px;
        padding-top: 28px;
        font-size: 1.2rem;
        display: grid;
        /* align-content: stretch; */
        grid-template-rows: 58px 40px 24px;

        #classname-div {
            font-size: 2rem;

            .class-name {
                color: #34acde;
                font-size: 2rem;
            }
        }

        .unit-name {
            font-size: 2rem;
            /* margin: 18px 0 0px; */
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 720px) {
    .lesson-info-div {
        height: 182px;
        width: 95%;

        .img-div {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            // border: 4px #00a7e6 solid;
            text-align: center;
            line-height: 90px;
            // background-color: #fff;

            img {
                width: 76.91%;
                height: 76.91%;
                border-style: none;
                // border: 4px #c5c0bc solid;
                border-radius: 50%;
            }
        }

        .text-div {
            #classname-div {
                font-size: 1.4rem;

                .class-name {
                    color: #34acde;
                    font-size: 1.2rem;
                }
            }
        }

        .text-div {
            width: auto;
            padding-left: 26px;
            padding-top: 28px;
            font-size: 1.2rem;
            display: grid;
            /* align-content: stretch; */
            grid-template-rows: 28px 40px 24px;

            #classname-div {
                font-size: 1.2rem;

                .class-name {
                    color: #34acde;
                    font-size: 1.2rem;
                }
            }

            .unit-name {
                font-size: 1.4rem;
                /* margin: 18px 0 0px; */
                font-weight: 600;
            }
        }
    }
}
.purchase-courses {
    width: 80%;
    margin: auto;
    color: #252525;

    // 头部
    .header-text {
        margin-top: 80px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 3.4rem;
        font-weight: 700;
    }

    .payment-method-ul {
        display: flex;
        justify-content: space-between;

        .courses-single {
            display: none;
        }

        .courses-single-pc {
            text-align: center;
            margin-bottom: 50px;

            .time-btn {
                width: 184px;
                height: 46px;
                background-color: #ecd352;
                border-radius: 5px;
                color: #fff;
                font-weight: 600;
                margin-bottom: 42px;
            }

            .price {
                font-size: 6.4rem;
                font-weight: 700;

                .symbol {
                    line-height: 50px;
                    font-size: 1.6rem;
                    vertical-align: text-top;
                    font-weight: 700;
                }
            }

            .total-price {
                margin: 40px 0px 50px;
                font-weight: 500;
            }

            .per-text {
                font-weight: 500;
            }

            .start-btn {
                height: 58px;
                width: 100%;
                padding: 0 50px;
                border-radius: 10px;
                background-color: #fff;
                font-weight: 700;
                font-size: 1.4rem;
                border: 1.5px black solid;
            }

            .start-btn:hover {
                background-color: #ecd352;
                border: 1.5px #fff solid;
            }
        }

    }
}

@media only screen and (max-width:720px) {
    .purchase-courses {
        width: 100%;

        // 
        .header-text {
            height: 77px;
            line-height: 77px;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 5px;
            text-align: center;
            font-size: 1.6rem;
            font-weight: 700;
            background-color: #ecd352;
            color: #fff;
        }

        .payment-method-ul {
            display: flex;
            flex-direction: column;
            // margin: 0 1%;

            // pc端隐藏
            .courses-single-pc {
                display: none;
            }



            //中间
            .courses-single:hover {
                border: 4px #ecd352 solid;
                box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2), 0 1px 10px -10px rgba(0, 0, 0, 0.2);

            }

            .courses-single {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // border: 4px #fff solid;
                padding: 15px;
                border-radius: 10px;
                margin-bottom: 5px;

                .time-btn {
                    width: 137px;
                    height: 54px;
                    color: #252525;
                    background-color: #ecd352;
                    font-size: 1.6rem;
                    font-weight: 700;
                    border-radius: 10px;
                }

                .price {
                    font-size: 4.4rem;
                    font-weight: 700;


                    .symbol {
                        font-size: 1.2rem;
                        font-weight: 700;
                        vertical-align: text-top;
                        line-height: 30px;
                    }

                    .price-month {
                        font-size: 1.2rem;
                        font-weight: 400;

                    }
                }
            }
        }
    }
}
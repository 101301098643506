.order {
    width: 66.6666%;
    margin: 0 auto;
    padding: 100px 1.5rem;
    max-width: 420px;

    .order-content {
        border: 1px solid #ecd352;
        width: 100%;
        border-radius: 6px;
        color: #363636;

        .order-title {
            height: 55px;
            line-height: 55px;

            background-color: #ecd352;
            color: #fff;
            border-radius: 6px 6px 0 0;
            text-align: center;
            font-size: 16px;
            font-weight: 700;

        }

        .order-info {
            padding: 0 48px;

            .method-div {
                text-align: left;

                .right {
                    color: red;
                    font-size: 1.2rem;
                    font-weight: 700;
                }
            }


            p {
                padding: 8px 0px;
                font-size: 1.2rem;
            }

            .price {
                display: flex;
                flex-direction: row;
                height: 78px;
                line-height: 78px;
                padding: 0 20%;
                justify-content: space-around;

                .price-text {
                    font-size: 1.4rem;
                }

                .order-price {

                    font-size: 2rem;
                    font-weight: 700;
                }
            }

            .time {
                color: #7a7a7a;
                font-size: 1.2rem;
            }

            .pass-text {
                font-size: 1.2rem;
            }

            .reg {
                width: 100%;
                height: 38px;
                background-color: #ecd352;
                color: #fff;
                font-size: 1.4rem;
                font-weight: 700;
                margin: 16px 0;
            }

        }
    }
}

@media screen and (max-width: 720px) {
    .order {
        width: 90%;
    }
}